<template>
  <div class="wizard">
    <div class="container">
      <Wizard></Wizard>
      <form-wizard 
                  @on-complete="onComplete" 
                  :start-index="startIndex"
                  title="Configuração TikTok Business" 
                  :subtitle="null"
                  back-button-text="null"
                  next-button-text="AVANÇAR"
                  finish-button-text="FINALIZAR">
        <tab-content title="Autenticação" :before-change="beforeTabSwitch">
          <center>
            <h3>Olá, {{ nome }}! Falta pouco para você começar a anunciar no TikTok</h3>
            <p>Após a integração, você terá acesso ao dashboard com as estatísticas da sua loja.</p>
          </center>
        </tab-content>
        <tab-content title="Produtos">
          <center>
            <h3>Já selecionamos todos os seus produtos pra você!</h3>
            <p>Produtos sem foto ou preço não serão enviados para o TikTok.</p>
            <p>Não se preocupe, assim que atualizá-los eles serão enviados.</p>
          </center>
        </tab-content>
        <tab-content title="Quase lá">
          <center>
            <h3>Agora é só autorizar o acesso à sua conta TikTok.</h3>
          </center>
        </tab-content>
      </form-wizard>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Wizard from '@/components/Wizard.vue'
import {FormWizard, TabContent} from 'vue-form-wizard'
import '@/assets/css/vue-form-wizard.min.css'

export default {
  name: 'WizardPage',
  beforeMount() {
    this.startIndex = (this.$route.query.state) ? 1 : 0
  },
  mounted(){

    this.storeId = this.$route.query.state

    if(this.$route.query.state) {
       this.payload = {
        "auth_code": this.$route.query.auth_code,
        "code": this.$route.query.code,
        "state": this.storeId
       }
console.log('etapa 2')
console.log(this.payload)

      this.$http.get('tiktok/auth/callback', { params: this.payload })
      .then(res => {
        this.mensagem = res.data.message
        this.bc_url = res.data.bc_url
        console.log(this.bc_url)
        window.open(res.data.bc_url, "_self");
      }).catch(error => {
        if (error.response) {
          this.startIndex = 0
          this.mensagem = error.response.data.message
          this.$router.push('/error')
        }
      });
    }
  },
  components: {
    Wizard,
    FormWizard,
    TabContent
  },
  methods: {
    beforeTabSwitch: function(){
      this.storeId = this.$route.query.store_id

      this.payload = {
        "store_id": this.storeId
      }
console.log('beforeTabSwitch')
console.log(this.payload)
      this.$http.get('tiktok/business/tiktok-auth-url', { headers: {'Authorization': this.payload} }
      )
      .then(res => {
        this.mensagem = res.data.message
        this.bc_url = res.data.bc_url
        console.log(this.bc_url)
        window.open(res.data.bc_url, "_self");
      }).catch(error => {
        if (error.response) {
          this.startIndex = 0
          this.mensagem = error.response.data.message
          this.$router.push('/error')
        }
      });
      
      return true;
    },
    onComplete: function(){
      this.$router.push('/')
    }
  },
  data() {
    return {
      nome: null,
      startIndex: 0,
      payload: null,
      storeId: null,
      bc_url: null
    }
  },
}
</script>
